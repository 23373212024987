<template>

  <div>

    <v-row>
      <v-col
        md="10"
        offset-md="1"
      >
        <v-card>
          <v-toolbar>
            <!-- Adjust the height to your needs, mine is 40 -->
            <a
              href="/"
              style="margin-top: 10px"
            >
              <img
                class="mr-1"
                src="@/assets/2023-08-01_150014.jpg"
                height="40"
              />
            </a>

            <v-toolbar-title> ลงทะเบียนโครงการเต็มใจ</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <router-link
                class="mt-3"
                to="/login"
                style="text-decoration: none;"
              >ล็อกอิน</router-link>

            </v-toolbar-items>
          </v-toolbar>
          <ValidationObserver
            ref="observer"
            v-slot="{ valid }"
          >

            <v-card-text
              style="height:100%"
              class="mt-4"
            >
              <master-render
                :rows="rows"
                v-model="model"
              ></master-render>

              <div class="my-5">
                <v-divider></v-divider>
                <div class="mt-3 subtitle-1 success--text">กรุณาอัพโหลดรูปบัตรนักศึกษา</div>
                <ImagePreview
                  class="mt-1"
                  @all-image="images=$event"
                ></ImagePreview>
              </div>
            </v-card-text>
            <v-card-actions class="mt-7">
              <v-btn
                color="primary"
                @click="submit()"
                :disabled="!(valid)"
                v-if="showSubmitButton"
              >ลงทะเบียน</v-btn>

            </v-card-actions>
          </ValidationObserver>

        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { InputType, UserRole } from "./../js/constants";
import {
  asyncForEach,
  chunkArray,
  createDynamicModel,
  getUID,
} from "./../js/custom";
import { getPhysicians, getPriority } from "./../js/dropdown-service";
import CustomSecureBlock from "./../components/custom/custom-secure-block";
import ImagePreview from "./image-preview.vue";
import UploadFileMixins from "./../_mixins/upload-file-mixins";

export default {
  components: {
    CustomSecureBlock,
    ImagePreview,
  },
  mixins: [UploadFileMixins],
  data() {
    return {
      model: {},
      images: [],
      showSubmitButton: true,
      successUpload: 0,
    };
  },
  created() {
    this.createDialogModel();
  },
  methods: {
    createDialogModel() {
      this.model = {};
      createDynamicModel(this, this.model, this.rows);
    },
    submit() {
      this.showSubmitButton = false;
      let url = "/member/register";
      this.$http
        .post(url, this.model)
        .then((res) => {
          if (this.images.length == 0) {
            this.redirectToLogin();
          } else {
            // this.uploadImages(res.id);
            // let items = this.handleFileChanges(this.images);
            let uploadUrl = "/member/uploadFile";
            let appendForm = Object.assign({}, { memberId: res.id });

            this.uploadFiles(this.images, uploadUrl, appendForm);
          }
        })
        .catch((er) => {
          this.showSubmitButton = true;
        });
    },
    handleUploadSuccess() {
      if (this.uploadFileModel.completed == this.uploadFileModel.total) {
        this.redirectToLogin();
      }
    },
    redirectToLogin() {
      this.showAlertSuccess("สมัครสมาชิกสำเร็จ");

      setTimeout(() => {
        this.$router.push({
          name: "login",
        });
      }, 1000);
    },
    // uploadImages(memberId) {
    //   let splited = chunkArray(this.images, 3);

    //   splited.forEach((aChunk) => {
    //     asyncForEach(aChunk, async (item, index) => {
    //       const formData = new FormData();
    //       formData.append("file", item.file);
    //       formData.append("fileName", item.fileName);
    //       formData.append("memberId", memberId);

    //       item.uid = getUID();
    //       await this.createRequest(formData, item.uid, 3);
    //     });
    //   });
    // },

    // createRequest(formData, uid, retry) {
    //   let url = "/member/UploadFile";

    //   return this.$http
    //     .uploadFile(url, formData)
    //     .then((res) => {
    //       this.successUpload++;
    //       if (this.successUpload == this.images.length) {
    //         this.redirectToLogin();
    //       }
    //     })
    //     .catch((err) => {
    //       if (retry) {
    //         retry--;
    //         this.createRequest(formData, uid, retry);
    //       } else {
    //       }
    //     })
    //     .finally(() => {});
    // },
  },
  computed: {
    rows() {
      return [
        [
          {
            name: "namePrefix",
            text: "คำนำหน้าชื่อ",
            type: InputType.dropdown,
            data: {
              url: "/dropdown/namePrefixes",
            },
            rule: {
              required: true,
            },
            columnClass: "col-8 col-md-3",
          },
          {
            type: InputType.offset,
            columnClass: "col-4 col-md-9",
          },
          {
            name: "firstNameThai",
            text: "ชื่อ (ภาษาไทย)",
            type: InputType.text,
            rule: {
              required: true,
            },
            columnClass: "col-12 col-md-3",
          },
          {
            name: "lastNameThai",
            text: "นามสกุล (ภาษาไทย)",
            type: InputType.text,
            rule: {
              required: true,
            },
            columnClass: "col-12 col-md-3",
          },
          {
            name: "firstNameEnglish",
            text: "ชื่อ (ภาษาอังกฤษ)",
            type: InputType.text,
            rule: {
              required: true,
            },
            columnClass: "col-12 col-md-3",
          },
          {
            name: "lastNameEnglish",
            text: "นามสกุล (ภาษาอังกฤษ)",
            type: InputType.text,
            rule: {
              required: true,
            },
            columnClass: "col-12 col-md-3",
          },
          {
            name: "nickName",
            text: "ชื่อเล่น",
            type: InputType.text,
            rule: {
              max: 20,
            },
            columnClass: "col-12 col-md-3",
          },
          {
            name: "birthDate",
            text: "วันเกิด(ตัวอย่าง 31/12/2530)",
            type: InputType.datePicker,
            rule: {},
            columnClass: "col-12 col-md-3",
          },
          {
            name: "email",
            text: "Email",
            type: InputType.text,
            rule: {
              required: true,
              email: true,
              max: 40,
            },
            columnClass: "col-12 col-md-3",
          },
          {
            name: "tel",
            text: "เบอร์โทรศัพท์",
            type: InputType.text,
            rule: {
              max: 11,
            },
            columnClass: "col-12 col-md-3",
          },
          {
            name: "lineId",
            text: "Line Id",
            type: InputType.text,
            rule: {
              max: 30,
            },
            columnClass: "col-12 col-md-3",
          },
        ],
        [
          {
            name: "university",
            text: "สถานศึกษา",
            type: InputType.dropdown,
            data: {
              url: "/dropdown/universities",
            },
            rule: {
              required: true,
            },
            columnClass: "col-12 col-md-4",
          },
        ],
        [
          {
            name: "faculty",
            text: "คณะ",
            type: InputType.dropdownRelate,
            parent: "university",
            data: {
              url: "/dropdown/faculties?universityId=",
            },
            rule: {
              required: true,
            },
            columnClass: "col-12 col-md-4",
          },
        ],
        [
          {
            name: "major",
            text: "สาขา",
            type: InputType.dropdownRelate,
            parent: "faculty",
            data: {
              url: "/dropdown/majors?facultyId=",
            },
            rule: {
              required: true,
            },
            columnClass: "col-12 col-md-4",
          },
        ],
        [
          {
            type: "section",
            text: "Credential",
          },
        ],
        [
          {
            name: "idCardNumber",
            text: "เลขประจำตัวประชาชน",
            type: InputType.text,
            rule: {
              required: true,
              idCardNumber: true,
              min: 13,
              max: 13,
            },
            columnClass: "col-12 col-md-4",
          },

          {
            name: "password",
            text: "Password",
            type: InputType.password,
            hideWhenEdit: true,
            rule: {
              required: true,
              regex: "",
              min: 4,
              max: 12,
            },
            columnClass: "col-12 col-md-4",
          },
          {
            name: "confirmPasword",
            text: "Confirm Password",
            type: InputType.password,
            hideWhenEdit: true,
            rule: {
              confirmed: "password",
              required: true,
            },
            columnClass: "col-12 col-md-4",
          },
        ],
      ];
    },
  },
};
</script>


<style>
.title-page img {
  width: 70px;
  padding-top: 0px;
  margin-top: -20px;
}

.title-page span {
  display: block;
  margin-left: 100px;
  margin-top: -50px;
}
</style>